<template>
    <div>
        <el-row class="row">
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-image src="/logo.png" fit="cover" style="height: 4rem; width: 12rem;"></el-image>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-image src="/Home/menu.png" fit="cover" style="width: 2rem; height: 2rem; margin-top: 1rem; margin-right: 1rem; float:right;"
                    @click="drawer = true"></el-image>
            </el-col>
        </el-row>
        <el-drawer v-model="drawer" :with-header="false" size="70%">
            <el-menu mode="vertical" style="border:none;">
                <el-menu-item index="1" @click="handleChangeMenu('/Home')">{{ $t("menu.home") }}</el-menu-item>
                <el-sub-menu index="2">
                    <template #title>{{ $t("menu.industry") }}</template>
                    <el-sub-menu v-for="(item, i) in industry.datas" :key="i" :index="'2-' + i">
                        <template #title>{{ $t("industry." + item.category) }}</template>
                        <el-menu-item v-for="(obj, j) in item.datas" :key="j" :index="'2-' + i + '-' + j"
                            @click="handleChangeMenu('/Industry/' + item.category + '/' + obj.key)">{{
                                $t(item.category + '.' + obj.key) }}</el-menu-item>
                    </el-sub-menu>
                </el-sub-menu>
                <el-sub-menu index="3">
                    <template #title>{{ $t("menu.product") }}</template>
                    <el-menu-item v-for="(item, index) in product.datas" :key="index" :index="'3-' + index"
                        @click="handleChangeMenu('/Product/' + item.key)">{{ $t("product." + item.key) }}</el-menu-item>
                </el-sub-menu>
                <el-menu-item index="4" @click="handleChangeMenu('/Project')">{{ $t("menu.project") }}</el-menu-item>
                <el-menu-item index="5" @click="handleChangeMenu('/News')">{{ $t("menu.news") }}</el-menu-item>
                <el-menu-item index="6" @click="handleChangeMenu('/ContactUs')">{{ $t("menu.contactUs") }}</el-menu-item>
            </el-menu>
        </el-drawer>
        <el-row class="row" style="height: auto;">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-image :src="industry.topImage" fit="cover" style="width: 100%;"></el-image>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import industry from "/public/Json/industry.json";
import product from "/public/Json/product.json";

export default {
    name: "HeaderMini",
    data() {
        return {
            drawer: false,
            industry,
            product,
        };
    },
    mounted: function () { },
    methods: {
        handleChangeMenu(path) {
            this.drawer = false
            this.$router.push({
                path: path,
            });
        }
    }
};
</script>

<style scoped>
.row {
    background-color: rgb(0, 35, 52);
    height: 4rem;
}

.div_menu {
    margin-bottom: 20px;
}

.div_menu_sub {
    margin-left: 20px;
}

:deep(.el-drawer.rtl) {
    overflow: auto !important;
}
</style>