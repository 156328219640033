<template>
    <el-image src="/logo_zh.png" style="width: 100%;"></el-image>
    <div style="padding: 0 1em;">
        <Main></Main>
    </div>
</template>

<script>
import { Main } from "./components";

export default {
    name: "Layout",
    components: {
        Main
    }
};
</script>