import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'

// 国际化
import i18n from './lang/index.ts';

import './assets/icons'

import 'animate.css';

const app = createApp(App)
installElementPlus(app)
app.use(store).use(router).use(i18n).mount('#app')