<template>
    <div>
        <Header v-if="screenWidth >= 800"></Header>
        <HeaderMini v-else></HeaderMini>
        <Main></Main>
        <Footer v-if="screenWidth >= 800"></Footer>
        <FooterMini v-else></FooterMini>
    </div>
</template>

<script>
import { Header, HeaderMini, Main, Footer, FooterMini } from "./components";

export default {
    name: "Layout",
    components: {
        Header,
        HeaderMini,
        Main,
        Footer,
        FooterMini
    },
    data() {
        return {
            screenWidth: document.body.clientWidth
        };
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            if (!this.timer) {
                // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                this.screenWidth = val
                this.timer = true
                let that = this
                setTimeout(function () {
                    that.timer = false
                }, 400)
            }
        }
    },
    mounted: function () {
        const that = this
        window.addEventListener('resize', function () {
            window.screenWidth = document.body.clientWidth
            that.screenWidth = window.screenWidth
        })
    },
};
</script>