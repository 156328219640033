import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
import WXLayout from '@/layout/wxLayout'

const routes = [
  {
    path: '/Management/login',
    component: () => import('@/views/management'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/Home',
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: 'Industry/:category/:key',
        name: 'Industry',
        component: () => import('@/views/industry')
      },
      {
        path: 'Product/:key',
        name: 'Product',
        component: () => import('@/views/product')
      },
      {
        path: 'Project',
        name: 'Project',
        component: () => import('@/views/project')
      },
      {
        path: 'News',
        name: 'News',
        component: () => import('@/views/news')
      },
      {
        path: '/ContactUs',
        name: 'ContactUs',
        component: () => import('@/views/contactUs')
      },
    ]
  },
  {
    path: '/wx',
    component: WXLayout,
    children: [
      {
        path: '/wx/Home',
        name: 'WxHome',
        component: () => import('@/views/wxViews/home')
      },
      {
        path: '/wx/Industry/:category',
        name: 'WxIndustry',
        component: () => import('@/views/wxViews/industry')
      },
      {
        path: '/wx/Product',
        name: 'WxProduct',
        component: () => import('@/views/wxViews/product')
      },
      {
        path: '/wx/Project',
        name: 'WxProject',
        component: () => import('@/views/wxViews/project')
      },
      {
        path: '/wx/News',
        name: 'WxNews',
        component: () => import('@/views/wxViews/news')
      },
      {
        path: '/wx/ContactUs',
        name: 'WxContactUs',
        component: () => import('@/views/wxViews/contactUs')
      },
      {
        path: '/wx/CallCenter',
        name: 'CallCenter',
        component: () => import('@/views/wxViews/callCenter')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router