<template>
    <el-footer>
        <el-row style="padding:50px 20px 0 20px">
            <el-col :xs="0" :sm="0" :md="5" :lg="5" :xl="5"> </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <el-row>
                    <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" style="color:rgb(153, 153, 153);">
                        <el-image src="/logo.png" fit="fill" style="height: 90px;"></el-image>
                        <table>
                            <tr v-for="(data, i) in callCenter.datas" :key="i">
                                <td style="width: 5em; padding-bottom: 10px; margin-top: 10px; vertical-align: top;">{{
                                    $t('callCenter.' + data.key) }}:
                                </td>
                                <td style="padding-bottom: 10px; margin-top: 10px;">
                                    <div v-if="data.key === 'preSalesPhone'">
                                        <div v-for="(value, j) in data.items" :key="j"
                                            :style="{ paddingTop: j === 0 ? '0' : '10px' }">
                                            <div>{{ value }}</div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-for="(obj, j) in data.group[0].items" :key="j"
                                            :style="{ paddingTop: j === 0 ? '0' : '10px' }">
                                            <div>{{ obj.phone }}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="footer_link">
                        <div class="footer_link_title">{{ $t("common.companyName") }}</div>
                        <div class="footer_link_item" @click="handleChangeMenu('/Project')">{{ $t("menu.project") }}</div>
                        <div class="footer_link_item" @click="handleChangeMenu('/ContactUs')">{{ $t("menu.contactUs") }}
                        </div>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="footer_link">
                        <div class="footer_link_title">{{ $t("menu.industry") }}</div>
                        <div class="footer_link_item" v-for="(item, index) in industry.datas" :key="index" :index="index"
                            @click="handleChangeMenu('/Industry/' + item.category + '/' + item.datas[0].key)">{{
                                $t("industry." + item.category) }}</div>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="footer_link">
                        <div class="footer_link_title">{{ $t("menu.product") }}</div>
                        <div class="footer_link_item" v-for="(item, index) in product.datas" :key="index" :index="index"
                            @click="handleChangeMenu('/Product/' + item.key)">{{ $t("product." + item.key) }}</div>
                    </el-col>
                    <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="footer_link">
                        <div style="float: right; width: 80%;">
                            <el-image src="/flight_qrcode.jpg" fit="fill"></el-image>
                            <div style="color:white; font-weight: bolder;">{{ $t("common.companyName") }}{{ $t("common.mp")
                            }}</div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <div class="copyright">
            <div>Copyright@ {{ $t("common.copyright") }}</div>
            <div><el-link href="https://beian.miit.gov.cn" target="_blank" style="color: white">辽ICP备2023004226号-1</el-link>
            </div>
        </div>
    </el-footer>
</template>

<script>
import industry from "/public/Json/industry.json";
import product from "/public/Json/product.json";
import callCenter from "/public/Json/callCenter.json";

export default {
    name: "Footer",
    data() {
        return {
            industry,
            product,
            callCenter
        };
    },
    methods: {
        handleChangeMenu(path) {
            this.$router.push({
                path: path,
            });
        }
    }
};
</script>

<style>
.el-footer {
    --el-footer-padding: 0 !important;
    background-color: rgb(0, 35, 52);
    height: auto !important;
}

.footer_link {
    text-align: center;
    color: white;
}

.footer_link_title {
    color: rgb(142, 195, 31);
    font-size: 20px;
    padding-bottom: 10px;
}

.footer_link_item {
    cursor: pointer;
    color: rgb(153, 153, 153);
    font-size: 16px;
    padding-bottom: 10px;
}

.copyright {
    text-align: center;
    color: rgb(153, 153, 153);
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 14px;
}
</style>