<template>
    <el-footer>
        <div class="copyright">
            <div>Copyright@ {{ $t("common.copyright") }}</div>
            <div><el-link href="https://beian.miit.gov.cn" target="_blank" style="color: white">辽ICP备2023004226号-1</el-link>
            </div>
        </div>
    </el-footer>
</template>

<script>
export default {
    name: "FooterMini",
};
</script>

<style>
.el-footer {
    --el-footer-padding: 0 !important;
    background-color: rgb(0, 35, 52);
    height: auto !important;
}

.copyright {
    text-align: center;
    color: rgb(153, 153, 153);
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 14px;
}
</style>