<template>
    <el-row class="row">
        <el-col :xs="0" :sm="0" :md="5" :lg="5" :xl="5" class="col_logo">
            <el-image src="/logo.png" fit="fill" style="height: 90px;"></el-image>
        </el-col>
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" class="menu">
            <el-row class="menu_row">
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col col_single" @click="handleChangeMenu('/Home')">
                    {{ $t("menu.home") }}
                </el-col>
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col">
                    <el-dropdown class="dropdown">
                        <span>{{ $t("menu.industry") }}</span>
                        <template #dropdown>
                            <el-dropdown-menu class="dropdown-menu">
                                <el-dropdown-item class="dropdown-item" v-for="(item, index) in industry.datas" :key="index"
                                    :index="index"
                                    @click="handleChangeMenu('/Industry/' + item.category + '/' + item.datas[0].key)">{{
                                        $t("industry." + item.category)
                                    }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-col>
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col">
                    <el-dropdown class="dropdown">
                        <span>{{ $t("menu.product") }}</span>
                        <template #dropdown>
                            <el-dropdown-menu class="dropdown-menu">
                                <el-dropdown-item class="dropdown-item" v-for="(item, index) in product.datas" :key="index"
                                    :index="index" @click="handleChangeMenu('/Product/' + item.key)">{{ $t("product." +
                                        item.key)
                                    }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-col>
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col col_single"
                    @click="handleChangeMenu('/Project')">{{
                        $t("menu.project") }}</el-col>
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col col_single" @click="handleChangeMenu('/News')">{{
                    $t("menu.news") }}</el-col>
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="col col_single"
                    @click="handleChangeMenu('/ContactUs')">{{
                        $t("menu.contactUs") }}</el-col>
            </el-row>
        </el-col>
        <el-col :xs="0" :sm="0" :md="5" :lg="5" :xl="5" class="col_logo" style="display:none;">
            <LangSelect class="set-language"></LangSelect>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'Home'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="home.topImage" fit="cover"></el-image>
            <div style="float: left; width: 100%; margin-top: -15%;">
                <el-row style="bottom:0;">
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"></el-col>
                    <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
                        <div v-for="(item, index) in industry.datas" :key="index"
                            :class="'icon_div_common icon_div_' + item.category"
                            @click="handleChangeMenu('/Industry/' + item.category + '/' + item.datas[0].key)"></div>
                    </el-col>
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"></el-col>
                </el-row>
            </div>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'Industry'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="industry.topImage" fit="cover" style="width: 100%;"></el-image>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'Product'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="product.topImage" fit="cover" style="width: 100%;"></el-image>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'Project'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="project.topImage" fit="cover" style="width: 100%;"></el-image>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'News'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="news.topImage" fit="cover" style="width: 100%;"></el-image>
        </el-col>
    </el-row>
    <el-row v-if="routeName === 'ContactUs'" class="row" style="height: auto;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-image :src="contact.topImage" fit="cover" style="width: 100%;"></el-image>
        </el-col>
    </el-row>
</template>

<script>
import home from "/public/Json/home.json";
import industry from "/public/Json/industry.json";
import product from "/public/Json/product.json";
import project from "/public/Json/project.json";
import news from "/public/Json/news.json";
import contact from "/public/Json/contactUs.json";

import LangSelect from '@/components/LangSelect'

export default {
    name: "Header",
    components: {
        LangSelect
    },
    data() {
        return {
            routeName: "Home",
            home,
            industry,
            product,
            project,
            news,
            contact,
        };
    },
    watch: {
        $route(to) {
            this.routeName = to.name;
        }
    },
    mounted: function () {
        this.routeName = this.$router.currentRoute._value.name;
    },
    methods: {
        handleChangeMenu(path) {
            this.$router.push({
                path: path,
            });
        }
    }
};
</script>

<style scoped>
.row {
    background-color: rgb(0, 35, 52);
    height: 90px;
}

.col_logo {
    background-color: rgb(0, 35, 52);
    height: 100%;
}

.logo_right {
    background-color: rgb(0, 35, 52);
    height: 100%;
    width: 90px;
    float: right;
    border-radius: 0 0 0 90px;
}

.menu {
    height: 100%;
}

.menu_row {
    height: 100%;
}

.col {
    width: 100%;
    font-size: 18px;
    color: white;
    text-align: center;
}

.col_single {
    padding-top: 33px;
    cursor: pointer;
}

.col:hover {
    background-color: rgb(37, 49, 61);
    color: rgb(142, 195, 31);
}

.dropdown {
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: white;
    padding-top: 37px;
}

.dropdown:hover {
    color: rgb(142, 195, 31);
}

.dropdown-menu {
    /* background-color: red; */
}

.dropdown-item {}

.set-language {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin-top: 18px;
    margin-right: 50px;
    float: right;
}

.icon_div_common {
    position: relative;
    /* bottom: 200px; */
    width: 18%;
    margin-right: 2%;
    padding-bottom: 18%;
    float: left;
}

.icon_div_Crane {
    background: url('/Industry/Crane/Crane.png') center no-repeat;
    background-size: contain;
}

.icon_div_Crane:hover {
    background: url('/Industry/Crane/Crane_click.png') center no-repeat;
    background-size: contain;
    cursor: pointer;
}

.icon_div_Compressor {
    background: url('/Industry/Compressor/Compressor.png') center no-repeat;
    background-size: contain;
}

.icon_div_Compressor:hover {
    background: url('/Industry/Compressor/Compressor_click.png') center no-repeat;
    background-size: contain;
    cursor: pointer;
}

.icon_div_WindPower {
    background: url('/Industry/WindPower/WindPower.png') center no-repeat;
    background-size: contain;
}

.icon_div_WindPower:hover {
    background: url('/Industry/WindPower/WindPower_click.png') center no-repeat;
    background-size: contain;
    cursor: pointer;
}

.icon_div_CalciumCarbideFurnace {
    background: url('/Industry/CalciumCarbideFurnace/CalciumCarbideFurnace.png') center no-repeat;
    background-size: contain;
}

.icon_div_CalciumCarbideFurnace:hover {
    background: url('/Industry/CalciumCarbideFurnace/CalciumCarbideFurnace_click.png') center no-repeat;
    background-size: contain;
    cursor: pointer;
}

.icon_div_CokeOven {
    background: url('/Industry/CokeOven/CokeOven.png') center no-repeat;
    background-size: contain;
}

.icon_div_CokeOven:hover {
    background: url('/Industry/CokeOven/CokeOven_click.png') center no-repeat;
    background-size: contain;
    cursor: pointer;
}
</style>