<template>
    <section class="app-main">
        <div style="padding: 0 20px;">
            <router-view :key="key" />
        </div>
    </section>
</template>

<script>
export default {
    name: "Main",
    computed: {
        key() {
            return this.$route.path;
        },
    },
};
</script>

<style lang="scss" scoped>
.app-main {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -5px;
    padding-top: 20px;
    background-color: white;
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
    .fixed-header {
        padding-right: 15px;
    }
}
</style>
