export default {
    common: {
        companyName: "弗莱德电气",
        location: "我的位置",
        mp: "公众号",
        copyright: "大连弗莱德电气技术有限公司 版权所有",
        suffix: "",
        loginTitle: "欢迎登录",
        userName: "请输入用户名",
        passWord: "请输入密码",
        publishedTime: "发布时间"
    },
    menu: {
        home: "首页",
        industry: "从事行业",
        product: "产品中心",
        project: "项目案例",
        news: "新闻动态",
        contactUs: "联系我们",
        serviceHotline: "服务热线",
    },
    home: {
        Introduction: "专业专致，助力腾飞",
        IntroductionParagraph1: "大连弗莱德电气技术有限公司成立于2015年，专注于冶金起重、鼓风、焦化、电石、风电行业，是工业自动化及智能化供应商，能够提供工业自动化及智能化系统设计、成套、安装、调试、售后服务等全流程方案解决，助力企业发展。",
        Service: "客户至上，服务为先",
        ServiceParagraph1: "公司时刻秉持“客户至上，服务为先”的服务理念，采用“服务工程师+服务平台”结合模式，工程师人员配置合理，平台架构科学先进，可为产品设备提供全生命周期管理服务，为企业生产保驾护航。",
        Recruit: "广聘贤才，知人善任",
        RecruitParagraph1: "公司提供广阔平台，与您共同成长，助您成就自我、辉煌人生，实现自我价值。"
    },
    industry: {
        Crane: "起重",
        Compressor: "鼓风",
        WindPower: "风电",
        CalciumCarbideFurnace: "电石炉",
        CokeOven: "焦炉",
    },
    Crane: {
        CDQ: "干熄焦提升机",
        CDQParagraph1: "干熄焦提升机是干法熄焦工艺中的关键设备，具有全自动运行、精确定位功能，能够高效的将装满红焦(约1050℃)的焦罐提升到提升塔顶，并沿轨道横移行走至装入料斗上方，与装入装置配合将红焦装入干熄炉内。装焦完成后，将空焦罐运回电机车上，进行下一个工作循环。整个过程由计算机程序自动控制，必要时也可人工干预。由于干熄焦系统连续性运转的工艺特性，因此要求干熄焦提升机具有很高的操作可靠性及频繁性。",
        CDQParagraph2: "大连弗莱德电气技术有限公司提供的干熄焦提升机电控系统具有使用安全可靠、配置和选型合理、性价比高、操作简单、人机协调性好、整机耐用的特点。公司自2015年创立以来，已完成了国内外50多台干熄焦提升机电控系统和安全监控系统的设计、采购、成套、调试、保产、运维和售后工作。",
        Bridge: "桥式起重机",
        BridgeParagraph1: "桥式起重机在各行各业有着广泛的应用，桥式起重机电控系统通常由配电保护柜（屏）、起升控制柜（屏）、走行控制柜（屏）、PLC控制柜（屏）、联动台、电阻器等构成，实现吊钩调运物品XYZ三维运行。",
        BridgeParagraph2: "大连弗莱德电气技术有限公司深耕起重行业近十年，对桥式起重机的切电阻调速、调压调速、变频调速，有完善可靠的控制和解决方案。专家工程师熟练掌握ABB、Siemens等变频器和PLC的调试。",
        Foundry: "铸造起重机",
        FoundryParagraph1: "铸造起重机是炼钢工艺中的关键设备，主要用于转炉加料跨向转炉兑铁水、钢水，在接受跨将钢水罐吊运到连铸大包回转台上或在精炼跨将钢水罐吊运到精炼炉上。铸造起重机在有热辐射的高温、高粉尘及含有有害气体的恶劣环境中工作，对设备电控系统具有较高的耐热、抗震、防尘要求。",
        FoundryParagraph2: "大连弗莱德电气技术有限公司创立至今，已完成如鞍钢、马钢、沙钢等国内大型钢铁企业铸造起重机的电控系统供货和改造工作。",
        Gantry: "门式起重机",
        GantryParagraph1: "门式起重机，又叫龙门吊，是桥式起重机的一种变形，主要用于室外的货场、料场、散货的装卸作业。其金属结构像门形框架，承载主梁下安装两条支脚，可以直接在地面的轨道上行走，主梁两端可以具有外伸悬臂梁，具有场地利用率高、作业范围大、适应面广、通用性强等特点。",
        GantryParagraph2: "大连弗莱德电气技术有限公司，对门式起重机的大车纠偏功能，有独特的专业算法，控制精度高、大车刚腿挠腿同步性好。可提供20t~300t门式起重机的整套电控系统。",
        Port: "港口起重机",
        PortParagraph1: "港口起重机是货运船只和货运目的地之间的重要衔接部分。随着船舶大型化的发展，港口起重机需要具备更高的效率和生产力。",
        PortParagraph2: "大连弗莱德电气技术有限公司集电气化、自动化和数字化于一体，有行业内从业二十余年的专家级工程师，熟练设计调试抓斗卸船机、连续卸船机、装船机、岸边集装箱起重机、集装箱门式起重机、门座起重机、钢板卸船机的电控系统。具备大至上千kW，小至几kW电机的配套电控系统设计调试能力。",
        Smart: "智能化起重机",
        SmartParagraph1: "随着社会生产水平的进步，智能起重输送系统技术在国内外得到越来越多的重视和应用，公司提供的智能化起重机系统以自动化和人工智能技术为核心，在物资的装卸、仓储、管理和控制各环节取消或减少人工操作，全过程实现集成化、智能化、自动化控制。它具有节约用地、减轻劳动强度、消除差错、提高管理水平、提高管理和操作人员素质、提高生产效率等诸多优点。",
        SmartParagraph2: "公司提供的智能化起重机系统，将有利于降低人员劳动强度及安全风险，提高生产效率，提高空间利用率。同时也能及时提供各种物料信息，为管理决策、计划统计提供准确、迅捷的数据支撑。",
    },
    Compressor: {
        Cloud: "云平台",
        CloudParagraph1: "数据云平台展示客户公司（包括子公司）下的所有设备，监控设备，以及每台设备的详情信息。",
        CloudParagraph2: "实时监控的设备，会展示实时数据曲线，发现设备是否存在问题，发现问题时可以进行设备的告警来提醒客户，同时专家也会进行远程诊断来修复问题。系统会记录所有的告警信息和远程诊断。",
        CloudParagraph3: "专家会通过远程诊断页面来帮助客户检查问题，解决问题。系统还会不定期的根据监控数据，推送相关设备的近期诊断，可以与客户进行交流来帮助客户解决疑惑。更好的维护设备。",
        CloudParagraph4: "客户可以从资料库中查看相关文档，视频来了解产品的信息，也可从资料库中下载自己设备所需要的驱动。",
        CloudParagraph5: "云平台提供视频培训，通过直播，视频的方式对用户进行相关设备的培训，了解行业动态信息。同时，用户可以通过在线客服进行答疑。"
    },
    WindPower: {
        SlipRings: "变桨滑环",
        SlipRingsParagraph1: "公司产品研发团队从2015年开始从事风电变桨滑环的研发，伴随着风力发电行业的高速发展，我们积累了大量风电滑环的开发、应用经验。公司至今已设计生产100余种规格型号的变桨滑环，投入市场运营产品3000台以上，覆盖市场98%以上风电机型，产品取得良好的应用效果。公司生产的滑环产品全寿命周期内免维护，使用寿命超过1亿转。采用了技术先进的贵金属环体及贵金属电刷，从根本上确保了超长的使用寿命。滑环小巧轻便，极大的降低了现场的工作强度。先进的抗振设计，完全满足风机的振动要求。独特的全密封结构，满足多沙多尘地区和海上风机的恶劣环境要求。抗凝露设计，防雷设计等性能充分满足了各地风机的使用要求。",
        SlipRingsParagraph2: "公司生产基地拥有非常先进的无尘化生产车间，每台滑环的出产都需要经过30多道工序、8项严格测试才能完成，我们对产品质量的要求非常苛刻，就是为了保证给客户的产品为100%合格。在研发阶段，新开发出来的产品需要经历严格的环境测试，包括温度测试、湿度测试、振动测试、绝缘阻抗、电性能、动态阻抗等项目，以保证产品在苛刻的工业环境下也能正常运行。在生产阶段，产品要经历功能测试、老化测试以确保所有产品的功能都能达到设计要求。",
    },
    CalciumCarbideFurnace: {
        DCS: "DCS",
        DCSParagraph1: "电石炉是生产电石的主要设备，电石炉控制系统的每个工艺区相对独立，I/O点以离散量为主，模拟量为辅。由于这些特点，自控系统采用集散型控制系统，由工厂自动控制系统及自动化仪表组成的检测控制系统——现场控制站，现场控制站由两套冗余控制器、两套冗余系统指示卡和两套冗余通讯卡构成，对电石炉车间各过程进行分散控制，再由冗余通讯系统和监控计算机组成的中央控制系统——中央控制室，对生产线实行集中管理。控制站与中央控制室之间由冗余工业以太网进行数据通讯。现场控制站与现场测控仪表之间采用开放式现场总线连接。",
        DCSParagraph2: "大连弗莱德电气技术有限公司提供的DCS自动化系统遵循的设计原则是以操作简单、管理水平可靠为前提，积极采用新技术、新工艺、新设备和新材料，提高技术经济指标，为电石炉DCS系统长期稳定高效地运行提供了技术保障，同时实现电石炉车间的现代化生产管理。",
    },
    CokeOven: {
        CokeOven: "焦炉四大车",
        CokeOvenParagraph1: "焦炉车辆由装煤车、推焦机、拦焦机、熄焦车组成。",
        CokeOvenParagraph2: "（1）装煤车负责在推焦车推完焦后把事先捣固好的煤饼装入焦炉内；",
        CokeOvenParagraph3: "（2）推焦机负责煤饼在焦炉内烧成焦炭后，把其从焦炉内的推出；",
        CokeOvenParagraph4: "（3）拦焦机负责和推焦机同时工作，在推焦机推焦时，拦焦机已经开启炉门对好导焦栅，让被推出的焦炭从导焦栅内通过，掉入熄焦车焦罐内，起引导作用；",
        CokeOvenParagraph5: "（4）熄焦车负责配合推焦机和拦焦机工作。在推焦前，和拦焦机一起对好下一炉号，将其焦罐对好上方拦焦机的导焦栅的出口处，在推焦时，接住焦炭。推焦完毕后，将焦炭用熄焦车机车头牵引焦罐至干熄焦提升塔下方，供干熄焦提升机向干熄炉内装焦使用。",
        CokeOvenParagraph6: "在电机驱动中，作为大型机电一体化设备，动力电源多以AC380V为主，使用专用的滑触线取电。在生产的过程中，电气设备控制着整个操作，电机、无触点开关、限位开关等相互合作，共同完成走行、摘门、挂门、推焦、平煤等机动步骤。由于需要完成比较复杂的操作，整个流程环节涉及的步骤较多，因此，各种各样的电气设备存在于推焦车上，具有一定的复杂性。大连弗莱德电气技术有限公司，为多台套焦炉四大车配套电控系统，受到主机厂的青睐。"
    },
    product: {
        ElectricalControlSystem: "电控系统成套控制柜",
        ElectricalControlSystemParagraph1: "大连弗莱德电气技术有限公司业务涵盖工业自动化及智能化电气系统设计、成套、安装、调试、售后服务等全流程方案解决，为用户提供交钥匙工程。 主要有干熄焦提升机电控系统、铸造起重机电控系统、桥式起重机电控系统、岸桥电控系统、卸船机电控系统、装船机电控系统、电石炉电控系统、鼓风机电控系统、焦炉四大车电控系统等。",
        MCD: "边缘服务器",
        MCDParagraph1: "边缘服务器（型号：MCD3000）安全监控主机是集多种功能于一身的综合性电子装置，可广泛应用于工业现场的信号采集、处理、逻辑控制及TCP/UDP的组网通信，监控系统存储的数据均具有时间戳，并按时间顺序排列，可任意调取某一时间内的数据进行查看,保障设备的安全稳定运行。",
        AbsoluteEncoder: "绝对值编码器",
        AbsoluteEncoderParagraph1: "大连弗莱德电气技术有限公司生产的绝对值编码器，内部由绝对值码盘组成，机械齿轮组真多圈，能够实现全数字化计值。支持多种协议输出，方便连接各种PLC及上位机。绝对值编码器多用途、多功能，支持多地址设定、通讯波特率设定、分辨率和方向设定。绝对值编码器安装方便快捷，无需找零，同时提供绝对值编码器安装支架，设备使用时直接焊接安装即可，无需再次进行机加工或预留安装接口。",
        CraneSteelyard: "起重机电子秤",
        CraneSteelyardParagraph1: "大连弗莱德电气技术有限公司研发的起重机电子秤，具有称重显示和超载限制的功能。整套系统由称重传感器、接线盒(信号变送器)、主机仪表、大屏幕显示器及连接电缆线等部分组成。适用于普通桥式起重机、铸造起重机、门式起重机、干熄焦提升机等设备。",
        WireRopeInspection: "钢丝绳检测装置",
        WireRopeInspectionParagraph1: "钢丝绳的质量是现代大型钢结构装置提升系统的安全保障，智能化电磁检测是钢丝绳无损检测的主要方法，已成为运行中钢丝绳在线检测最广泛的缺陷检测技术。  公司的钢丝绳电磁检测方法主要包括漏磁和主磁通检测及其在钢丝绳断丝、磨损、锈蚀、变形等定量化检测。 ",
        CodeRail: "编码尺测距系统",
        CodeRailParagraph1: "编码尺绝对定位系统，主要由读头、编码尺、接口模块和安装附件组成。编码尺读头和编码尺专为在户外严苛环境条件下使用而开发，能够抵抗在室外环境中各种灰尘等。基于编码穿孔检测，测量原理可确保提供很强的抗污染能力，从而确保操作安全性。读头无接触、无磨损地扫描编码尺，使解决方案完全免维护。此外，特殊涂层的不锈钢编码尺还适用于弯道、上升、下降、中断和轨道开关等情况。",
        Reactor: "进出线电抗器",
        ReactorParagraph1: "变频器用进线电抗器用于与变频器的进端相串联，作为变频器的进线电抗器。变频器在工作时将产生较大的谐波，如果不加装进线电抗器，将大幅度降低变频器及用电设备的使用寿命，谐波干扰严重时，会导致某些设备不能正常运行，加装进线电抗器后将大幅度降低各谐波的含量，提高电网的功率因数，保证变频器及其它用电设备的安全运行，确保其它用电设备不受谐波干扰影响，而且该电抗器在负载合闸瞬间能够有效地抑制回路涌流，保护回路中的变频器装置及其它元器件免受过电流冲击。",
        TransmitterDecoder: "增量编码器光电模块",
        TransmitterDecoderParagraph1: "光纤发射器是用来把编码器3路HTL电平转为光纤的设备",
        TransmitterDecoderParagraph2: "光纤解码器是用来把发射器发来的光纤信号转为编码器HTL的设备",
        TransmitterDecoderParagraph3: "主要用来延长通信距离或隔离电气间的直接连接，最远可达1公里",
        TransmitterDecoderParagraph4: "不需要任何配置，插上即用",
        FLDSCADA: "组态软件",
        FLDSCADAParagraph1: "组态软件（FLD-SCADA） 是基于公司在超过7年时间内，向多个钢厂的用户提供数千套安全监控的SCADA软件的实践经验基础上，同时结合当前云计算和物联网技术潮流而进行架构和设计的世界级水平的组态系统开发平台。FLD-SCADA是一款综合的软件开发工具包，既满足于宽范围的工业行业软件应用要求，又保留了传统组态软件的易用性。",
    },
    project: {
        Foundry: "铸造起重机案例",
        FoundryParagraph1: "大连弗莱德电气技术有限公司承接辽宁某钢铁企业180t铸造起重机大车机构调压调速装置更换为变频器项目。原大车机构采用调压调速控制，启停过程对端梁绞轴冲击大，导致绞轴每6个月需要更换一次。更换为变频控制后，大车机构启停过程平滑线性，原6个月需要更换一次的绞轴，延长到3年更换一次，使用寿命提高了600%。原采用调压调速控制方式，电动机处于发电状态时，电能以热量的形式通过电阻器消耗，现采用变频控制后，整流回馈单元将电动机发电回馈电网，节能环保。",
        CDQ: "干熄焦提升机案例",
        CDQParagraph1: "大连弗莱德电气技术有限公司为山西某项目配套的93t干熄焦提升机电控系统，PLC采用热备冗余方案，保障提升机每两年工作705天，每天24小时的连续运转。系统变频器采用整流回馈技术，当电动机工作在电动状态时，消耗电能；当电动机工作在制动状态时，产生电能并通过整流回馈装置直接回馈给电网，解决了传统系统中电动机制动能量通过热量散失的高能耗问题，达到了节能环保、提高设备运行可靠性、减少设备维护量和设备占用空间的目的。大连弗莱德电气技术有限公司设计干熄焦提升机电控系统的原则是：使用安全可靠、配置和选型合理、性价比高、操作简单、人机协调性良好、整机适用、耐用。",
        CokeOvenVehicles: "焦炉车辆案例",
        CokeOvenVehiclesParagraph1: "广东某焦化企业焦炉机械设备凭借先进的硬件基础及生产管理手段，经过几年不断改进和提升，成为了业内的佼佼者。如何利用先进的生产管理及操作模式创新，提高设备操作效率、降低劳动强度、改善操作环境，以先进、安全、实用、可靠为原则，借鉴国外大型焦炉机械的先进技术，结合自身工艺特点及焦炉机械设备运行状态，进一步提升和完善设备的自动化水平，达到可靠性高、维护量低、安全有效的目标，是日后生产管理的主要任务。",
        CokeOvenVehiclesParagraph2: "大连弗莱德电气技术有限公司针对该企业焦炉机械设备的发展需求，结合设备自身电气控制系统的特点，在设备本地操作基础上，通过关键控制策略技术的融入，实现由中控室远程操作的焦炉车辆无人值守智能控制的全自动生产管理目标。在原本地车辆手动操作基础上，对现有焦炉机械进行软、硬件的升级改造，增设必要的软硬件功能，优化现有系统配置。升级内容主要涵盖车辆设备、地面无线视频传输、中控系统及视频自动调取、数字孪生平台、安全防范系统等范围，具体包含推焦车2台、拦焦车2台、装煤车2台、电机车2台。",
        CokeOvenVehiclesParagraph3: "升级后的焦炉车辆机械设备集安全、稳定、高效、环保为一身，有效降低工人劳动强度60%，减少人力成本40%，提高生产效率20%，降低设备故障率80%，达到了节能减排的良好效果，提升故障预防维修方面效能，保证了焦炉车辆的稳顺运行。"
    },
    contactUs: {
        address: "联系地址",
        addressV: "辽宁省大连市高新技术产业园区广贤路129号赛伯乐大厦八层",
        phone: "联系电话",
        phoneV: "0411-86805301",
        technicalSupport: "技术支持",
        technicalSupportV1: "13804252204",
        technicalSupportV2: "13804251084",
        ProcurementEmail: "采购邮箱",
        ProcurementEmailV: "flight_pd{'@'}163{'.'}com",
        TechnicalEmail: "技术邮箱",
        TechnicalEmailV: "flight_tech{'@'}163{'.'}com",
    },
    callCenter: {
        preSalesPhone: "售前电话",
        afterSalesPhone: "售后电话",
        systemEngineer: "系统工程师",
        softwareEngineer: "软件工程师",
    }
}