
// 自定义国际化配置
import { createI18n } from 'vue-i18n'
import { localStorage } from '../utils/storage.ts'

// 本地语言包
import enLocale from './en.ts'
import zhCnLocale from './zh-cn.ts'

const messages = {
    'zh-cn': {
        ...zhCnLocale
    },
    en: {
        ...enLocale
    }
}

/**
 * 获取当前系统使用语言字符串
 * 
 * @returns zh-cn|en ...
 */
const getLanguage = () => {
    // 本地缓存获取
    let language = localStorage.get('language')
    if (language) {
        return language
    }
    // 浏览器使用语言
    language = navigator.language.toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale
        }
    }
    return 'zh-cn'
}

const i18n = createI18n({
    locale: getLanguage(),
    // locale: 'zh-cn',
    legacy: false, //处理报错Uncaught (in promise) SyntaxError: Not available in legacy mode (at message-compiler.esm-bundler.js:54:19)
    messages: messages,
    globalInjection: true
});

export default i18n;